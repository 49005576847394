import React, { useState, useRef } from 'react';
import Image from 'next/image';
import { CommonBackContent } from '../Layouts/CommonBackContent';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import ProtextOrigin from './protectOriginal/index';

const FreQuestion = dynamic(() => import('./freQuestions/index'), {
  ssr: true,
});
import AiPower from './aiPower/index';

import { imgUrl } from '@/shared/utils';
const TopRight = dynamic(() => import('./TopRight/index'), {
  ssr: false,
});

import TopLeft from './TopLeft/index';
const MemoTopLeft = React.memo(TopLeft);

const AiShowGrid = dynamic(() => import('./aiShowGrid'), {
  ssr: true,
});

import CreaterFriend from './CreaterFriend/index';

const BottomFolder = dynamic(() => import('./BottomFolder'), {
  ssr: false,
});
import useScroll from '@/context/UseScrollHeight';
const ErasaServer = dynamic(() => import('./ErasaServer/index'), {
  ssr: true,
});
import { useTranslation } from 'next-i18next';
const CreatorSay = dynamic(() => import('./CreatorSay'), {
  ssr: true,
});
const CreatorsWorldwide = dynamic(() => import('./CreatorsWorldwide/index'), {
  ssr: true,
});
import AddAccountModal from '@/components/protectedAccount/AddAcountModal';

const MemoAddAccountModal = React.memo(AddAccountModal);

import dynamic from 'next/dynamic';

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const scrollHeight = useScroll();
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation('common');
  const AccountModalRef = useRef<any>();
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  const getAccount = (inputValue: string, selectedPlatform: string) => {
    setIsAddAccountModal(true);
    const timer = setTimeout(() => {
      AccountModalRef.current.triggerStep(selectedPlatform, inputValue);
    }, 400);
    return () => clearTimeout(timer);
  };

  return (
    <div>
      <MemoCommonBackContent style={{ paddingTop: '0px', maxWidth: '1200px', margin: '0 auto' }}>
        <div className={styles.topMianLogo}>
          <div className={styles.topMianLogoBg}>
            <Image
              src={imgUrl('https://res-front.pumpsoul.com/prod-erasa/image/1234.webp', 'small')}
              alt="erasa"
              width={375 * 0.5}
              height={770 * 0.5}
              fetchPriority="high"
              priority
              quality={70}
            />
          </div>
          <MemoTopLeft getAccount={getAccount}></MemoTopLeft>
          {!isMobile ? <TopRight></TopRight> : <></>}
        </div>
      </MemoCommonBackContent>
      <ProtextOrigin />
      <AiPower />
      <AiShowGrid />
      <CreaterFriend />
      {data.contentInfo['section-testimonial']?.title && <CreatorSay data={data} />}
      <MemoCommonBackContent style={{ padding: '1rem', maxWidth: '1200px', margin: '0 auto' }}>
        <CreatorsWorldwide />
      </MemoCommonBackContent>
      <ErasaServer></ErasaServer>
      {/* <RecommendedArticle /> */}
      <MemoCommonBackContent style={{ padding: '1rem', maxWidth: '1200px', margin: '0 auto' }}>
        <FreQuestion data={data} />
      </MemoCommonBackContent>
      <BottomFolder onOpenModal={() => setIsAddAccountModal(true)}></BottomFolder>
      {isAddAccountModal && (
        <MemoAddAccountModal
          ref={AccountModalRef}
          isModalOpen={isAddAccountModal}
          handleCancel={() => setIsAddAccountModal(false)}
          isHome={true}
        ></MemoAddAccountModal>
      )}

      {scrollHeight > 500 && isMobile ? (
        <div className={styles.bottomBtn}>
          <div onClick={() => setIsAddAccountModal(true)}>{t('Get Started Free')}</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomeContent;
