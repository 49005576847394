import { lazy, useEffect, useRef } from 'react';
import styles from './index.module.less';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
const Index = () => {
  const { t } = useTranslation('common');
  const containerRef = useRef(null);
  const list = [
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/1.webp',
    },
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/2.webp',
    },
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/3.webp',
    },

    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/4.webp',
    },

    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/5.webp',
    },
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/6.webp',
    },

    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/7.webp',
    },

    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/8.webp',
    },
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/9.webp',
    },
    {
      url: 'https://res-front.pumpsoul.com/prod-erasa/image/index-protect/10.webp',
    },
  ];
  useEffect(() => {
    const container = containerRef.current;

    const scrollStep = 1;
    const scrollInterval = 30;

    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {});
  return (
    <div>
      <h2 className={styles.header}>
        <span>{t('Protecting Spicy Creators and Originals Globally')}</span>
      </h2>
      <div className={styles.main} ref={containerRef}>
        {list.map((item: any, index: number) => (
          <Image src={item.url} key={index} alt="erasa" width={100} height={100} loading="lazy" />
        ))}
      </div>
    </div>
  );
};

export default Index;
