import styles from './index.module.less';
import { useTranslation } from 'react-i18next';
import NextImage from 'next/image';
import AddAccountInput from '@/components/AddAccountInput';
const TopLeft = ({ getAccount }: { getAccount: (inputValue: string, selectedPlatform: string) => void }) => {
  const { t } = useTranslation();
  const getAccountTemp = (inputValue: string, selectedPlatform: string) => {
    getAccount(inputValue, selectedPlatform);
  };
  return (
    <div className={styles.left}>
      <h1>
        <p style={{ width: '700px' }}>{t('Protect Your Content with')}</p>
        <p>{t('DMCA Takedown')}</p>
      </h1>
      <p>{t('The Ultimate Leak Removal Solution for Creators')}</p>
      <div className={styles.indicator}>
        <div className={styles.l1}>
          <span style={{ color: '#478FFF', width: '20px', lineHeight: 1.5 }}>
            <span
              style={{
                animationDelay: '0.1s',
                fontWeight: 'bold',
                animationIterationCount: 1,
              }}
            >
              9
            </span>
          </span>
          <span>{t('years of experience')}</span>
        </div>
        <div className={styles.l2}>
          <span style={{ color: '#F84DAD', lineHeight: 1.5 }}>
            <span
              style={{
                animationDelay: '0.4s',
                fontWeight: 'bold',
                animationIterationCount: 1,
              }}
            >
              5
            </span>
            <span
              style={{
                animationDelay: '0.5s',
                fontWeight: 'bold',
                animationIterationCount: 1,
              }}
            >
              M
            </span>
            <span
              style={{
                animationDelay: '0.6s',
                fontWeight: 'bold',
                animationIterationCount: 1,
              }}
            >
              +
            </span>
          </span>
          <span>{t('infringing links removed')}</span>
        </div>
      </div>
      <AddAccountInput getAccount={getAccountTemp}></AddAccountInput>
      <div className={styles.desc}>
        <NextImage
          src="/images/common/green-right.svg"
          alt="erasa"
          width={15}
          height={15}
          fetchPriority="high"
          priority
        ></NextImage>
        <span>{t('Free to start, No card needed.')}</span>
      </div>
    </div>
  );
};

export default TopLeft;
